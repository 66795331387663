/**
 * Little AbortController proxy module so we can swap out the implementation easily later.
 */
export const {
  AbortController
} = globalThis;
export const {
  AbortSignal
} = globalThis;
export const createAbortError = function (message) {
  if (message === void 0) {
    message = 'Aborted';
  }

  return new DOMException(message, 'AbortError');
};